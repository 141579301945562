export const aboutMuseumData = {
  header: "Wirtualne Muzeum Polskiego Państwa Podziemnego",
  video: "https://www.youtube.com/watch?v=Bj_n0d8VhnM",
  content: [
    {
      category: "Czym było Polskie Państwo Podziemne",
      desc: "Polski ruch oporu to fenomen w skali światowej – obejmował działania 400 tysięcy zakonspirowanych żołnierzy, 50 tysięcy urzędników pracujących w tajemnicy przed okupantem, sieci edukacji na wszystkich szczeblach, pomocy społecznej czy największego w Europie tajnego koncernu wydawniczego. Niestety sam termin Polskie Państwo Podziemne nie jest powszechnie znany. <br/> Ruch ten był niejednoznaczny, polifoniczny, a jego uczestnicy reprezentowali wiele grup i wizji Polski. Jedni walczyli z bronią w ręku, inni – angażując się w tajne nauczanie czy działalność kulturalną, wielu – podejmując nieustanne próby prowadzenia normalnego życia w czasach pobawionych norm i wszechobecnej pogardy. Bohaterami byli partyzanci, ci, którzy ukrywali przed grabieżą skarby kultury, ale i matki, z wielką determinacją troszczące się o swoje dzieci."
    },
    {
      category: "Historia powstania Muzeum i jego idea",
      desc: "W 2020 roku Fundacja na rzecz Wielkich Historii zainicjowała budowę Wirtualnego Muzeum Państwa Podziemnego. Zostało pomyślane jako miejsce, w którym bezpłatnie i bez ograniczeń można korzystać z materiałów dotyczących historii Polskiego Państwa Podziemnego. Naszym celem jest wyjście poza bieżące spory polityki historycznej i ukazanie fenomenu największego ruchu oporu w okupowanej przez Niemców Europie przez oddanie głosu samym świadkom. <br /> Czasy okupacji niemieckiej są dla Polski niezwykle ważne. To one ukształtowały nasze społeczeństwo takim, jakim jest dzisiaj. Wierzymy, że niezależnie od tego, że się różnimy, powinniśmy dążyć do rzetelnego poznania tamtego tragicznego okresu, akceptując różne perspektywy jego postrzegania i oceny. Dlatego przede wszystkim oddajemy głos źródłom oraz świadkom historii. Jednym z głównych zadań, które stawiamy przed sobą w procesie budowy muzeum, jest gromadzenie i udostępnianie archiwów rodzinnych, dzięki którym ukażemy zapomniane nieraz opowieści i postaci. Wierzymy, że każda historia jest ważna."
    },
    {
      category: "Co znajduje się w Muzeum?",
      desc: "Od trzech lat Fundacja na rzecz Wielkich Historii zajmuje się digitalizacją i pozyskiwaniem licencji na udostępnianie materiałów źródłowych związanych z Polskim Państwem Podziemnym. W zbiorach zgromadziliśmy już ponad 1300 jednostek, które pochodzą z największych instytucji archiwalnych i muzealnych w Polsce oraz od osób prywatnych. Digitalizujemy dokumenty, fotografię, prasę, nagrania audio i wideo, a także tworzymy odwzorowania 3D wyjątkowo cennych obiektów. Ze wszystkimi materiałami można zapoznać się bezpłatnie w naszym cyfrowym repozytorium. Bazując na zgormadzonych zbiorach stworzyliśmy wirtualną wystawę ukazującą strukturę oraz losy Polskiego Państwa Podziemnego, w której kluczową rolę odgrywa materiał źródłowy. Każdą część wystawy uzupełniają zadania przeznaczone dla uczniów historii klas ósmej szkoła podstawowej oraz czwartej szkoły średniej. Zadania są również dostępne w zakładce dla nauczyciela do pobrania w formacie pdf. Tym samym wirtualna wystawa została pomyślna w taki sposób, by mogła być wykorzystywana w szkołach jako narzędzie do nauki historii II wojny światowej."
    }
  ],
  partners: {
    top: {
      logos: [
        {
          src: "/assets/partners/mkidn.png",
          name: "Ministerstwo Kultury i Dziedzictwa Narodowego",
        },
        {
          src: "/assets/partners/symbole.png",
          name: "Symbole narodowe",
        },
      ],
      text: "Dofinansowano ze środków Ministra Kultury i Dziedzictwa Narodowego pochodzących z Funduszu Promocji Kultury – państwowego funduszu celowego"
    },
    description: "Wirtualne Muzeum Polskiego Państwa Podziemnego – etap IV <br /><br />  Dofinansowanie 161 000 złotych <br /> <br />  Zadanie Wirtualne Muzeum Polskiego Państwa Podziemnego – etap IV polega na digitalizacji 350 obiektów (muzealnych oraz audiowizualnych) i udostepnieniu ich za darmo w repozytorium cyfrowym.",
    main: [
      {
        category: "DOFINANSOWANIE FUNDACJI PZU:",
        logo: "/assets/partners/pzu.png",
        name: "PZU",
        text: "Fundacja PZU wsparła finansowo projekt Wirtualnego Muzeum Polskiego Państwa Podziemnego."
      },
      {
        category: "Twórca:",
        logo: "/assets/partners/wielkie_historie.png",
        name: "Wielkie historie"
      },
      {
        category: "Mecenas:",
        logo: "/assets/partners/pge_fundacja.png",
        name: "PGE Fundacja"
      }
    ],
    partnersList: {
      header: "Partnerzy",
      logos: [
        {
          src: "/assets/partners/buw.png",
          name: "Biblioteka Uniwesytecka w Warszawie",
        },
        {
          src: "/assets/partners/mhp.png",
          name: "Muzeum Historii Polski",
        },
        {
          src: "/assets/partners/archiwa_panstwowe.png",
          name: "Archiwa Państwowe",
        },
        {
          src: "/assets/partners/wbh.png",
          name: "Wojskowe Biuro Historyczne",
        },
        {
          src: "/assets/partners/rfe.png",
          name: "RadioFreeEurope",
        },
        {
          src: "/assets/partners/muzeum_niepodleglosci.png",
          name: "Muzeum Niepodległości",
        },
        {
          src: "/assets/partners/muzeum_regionalne.png",
          name: "Muzeum Regionalne",
        },
        {
          src: "/assets/partners/fgez.png",
          name: "Fundacja Generał Elżbiety Zawackiej",
        },
        {
          src: "/assets/partners/muzeum_ppw.png",
          name: "Muzeum Pamięci Powstania Warszawskiego",
        },
        {
          src: "/assets/partners/muzeum_stalowa.png",
          name: "Muzeum Regoionalne w Stalowej Woli",
        },
      ]
    }
  }
}