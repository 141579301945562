import { useContext } from "react";
import styles from "./Artifacts.module.scss";
import { ChapterContext } from "../../../App";
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  data: any;
}
const Artifacts: React.FC<Props> = ({ data }) => {
  const context = useContext(ChapterContext);

  if (!data?.length) {
    return null
  }

  return (
    <div className={styles.container}>
      <p className={styles.artifactsHeader}>Odkryj więcej:</p>
      {data.map((x: any, index: number) => {
        if (x.type === "Audio") {
          return (
            <div
              key={index}
              className={styles.item}
              onClick={() => context.setModalData({ type: "audio", data: x.files[0] })}
              onKeyDown={(e) => e.key == "Enter" && context.setModalData({ type: "audio", data: x.files[0] })}
              role="button"
              aria-label="Artefakty"
              tabIndex={0}
            >
              <div className={styles.itemDeco}>

              </div>
              <div className={styles.itemImageWrapper}>
                <LazyLoadImage
                  alt="Artefakt"
                  effect="blur"
                  src={x.img}
                  className={styles.audioIcon}
                />
                {x.type === "Audio" &&
                  <div className={styles.icon}>
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 7.5L0.249999 14.8612L0.25 0.138783L13 7.5Z" fill="#FCF3E7" /></svg>
                  </div>
                }
              </div>
              <div className={styles.itemContent}>
                <p className={styles.itemType}>{x.type}</p>
                <p className={styles.itemName}>{x.title}</p>
              </div>
            </div>
          )
        }
        if (x.type === "Video") {
          return (
            <div
              key={index}
              className={styles.item}
              onClick={() => context.setModalData({ type: "video", data: x.files[0] })}
              onKeyDown={(e) => e.key == "Enter" && context.setModalData({ type: "audio", data: x.files[0] })}
              role="button"
              aria-label="Artefakty"
              tabIndex={0}
            >
              <div className={styles.itemDeco}>

              </div>
              <div className={styles.itemImageWrapper}>
                <LazyLoadImage
                  alt="Artefakt"
                  effect="blur"
                  src={x.img}
                  className={styles.audioIcon}
                />
                {x.type === "Video" &&
                  <div className={styles.icon}>
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 7.5L0.249999 14.8612L0.25 0.138783L13 7.5Z" fill="#FCF3E7" /></svg>
                  </div>
                }
              </div>
              <div className={styles.itemContent}>
                <p className={styles.itemType}>{x.type}</p>
                <p className={styles.itemName}>{x.title}</p>
              </div>
            </div>
          )
        }
        // const galleryFiles = data?.filter((x: any) => x.type === "Dokumenty");
        return (
          <div
            key={index}
            className={styles.item}
            onClick={() => context.setModalData({ type: "gallery", data: data[x.id - 1].files })}
            onKeyDown={(e) => e.key == "Enter" && context.setModalData({ type: "gallery", data: data[x.id - 1].files })}
            role="button"
            aria-label="Artefakty"
            tabIndex={0}
          >
            <div className={styles.itemDeco}>

            </div>
            <div className={styles.itemImageWrapper}>
              <LazyLoadImage
                alt="Artefakt"
                effect="blur"
                src={x.img}
              />
            </div>
            <div className={styles.itemContent}>
              <p className={styles.itemType}>{x.type}</p>
              <p className={styles.itemName}>{x.title}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Artifacts