import { useContext } from "react";
import { ChapterContext } from "../../../App";
import { sumValuesFromArray } from "../../../utils";
import styles from "./ChapterLink.module.scss";

type Props = {
  slidesQuantity: number[];
  index: number;
  chapterTitles: { chapter: string, name: string }[];
  setBurgerOpen: (x: boolean) => void;
}

const ChapterLink: React.FC<Props> = ({
  slidesQuantity,
  index,
  chapterTitles,
  setBurgerOpen
}) => {
  const context = useContext(ChapterContext);

  const handleClick = (slide: number, chapter: number): void => {
    if (typeof context.fpRef !== 'undefined') {
      // @ts-ignore
      context.fpRef?.current?.fullpageApi.moveTo(slide, 0);
      context.setActiveChapter(chapter);
    }
    setBurgerOpen(false)
  }

  const prevSlidesQuantity = slidesQuantity.slice(0, index);

  const isActiveChapter = context.activeChapter == index + 1;

  const isFirst = index === 0;
  let sum: number;
  if (isFirst) {
    sum = 0;
    return (
      <li
        className={`${styles.chapter} ${isActiveChapter && styles.chapterActive}`}
        onClick={() => handleClick(sum + 1, index + 1)}
        onKeyDown={(e) => e.key == "Enter" && handleClick(sum + 1, index + 1)}
        role="menuitem"
        tabIndex={0}
      >
        <span className={styles.chapterNumber}>{chapterTitles[index].chapter?.replace("Rozdział", "")}</span>
        <span className={styles.chapterName}>{chapterTitles[index].name}</span>
      </li>
    )
  }

  sum = sumValuesFromArray(prevSlidesQuantity)

  return (
    <li className={`${styles.chapter} ${isActiveChapter && styles.chapterActive}`}
      onClick={() => handleClick(sum + 1, index + 1)}
      onKeyDown={(e) => e.key == "Enter" && handleClick(sum + 1, index + 1)}
      role="menuitem"
      tabIndex={0}
    >
      <span className={styles.chapterNumber}>{chapterTitles[index].chapter?.replace("Rozdział", "")}</span>
      <span className={styles.chapterName}>{chapterTitles[index].name}</span>
    </li>
  )
}

export default ChapterLink