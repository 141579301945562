import { FC, useContext, useState } from 'react';
import Charts from '../../components/Charts';
import styles from "./SlideT13.module.scss";
import NextChapterBtn from '../../components/common/NextChapterBtn';
import BottomPanel from '../../components/BottomPanel';
import { ChapterContext } from '../../App';
import ArticleBox from '../../components/ArticleBox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isLastSlide } from '../../utils';

type Props = {
  slide: any;
  currentSlide: number;
}

const SlideT13: FC<Props> = ({ slide, currentSlide }) => {
  const context = useContext(ChapterContext)
  const isActiveSlide = parseInt(slide.slideSymbol.split(".")[0]) === context.activeChapter && parseInt(slide.slideSymbol.split(".")[1]) == currentSlide;
  const [keepVisible, setKeepVisible] = useState(true);

  const handleOpenImgModal = () => {
    context.setModalData({
      type: "picture",
      data: slide.modalImg,
    })
  }

  const handleOpenSourceModal = () => {
    context.setModalData({
      type: "source",
      data: slide.sourceModal,
    })
  }

  setTimeout(() => isActiveSlide ? setKeepVisible(true) : setKeepVisible(false), 1000);

  const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];

  return (
    <div className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
        <div className={styles.slideWrapper}>
            <div className={styles.slideLeftColumn} tabIndex={0}>
                <ArticleBox slide={slide} />
                <div className={styles.textBox}>
                    <LazyLoadImage
                        alt="znak cytatu"
                        effect="blur"
                        src="/assets/quote-red.svg"
                        className={styles.textBox_icon}
                    />
                    {slide.quote1 && <p className={styles.textBox_main}>{slide.quote1}</p>}
                    {slide.quoteInfo && <span className={styles.textBox_desc}>{slide.quoteInfo}</span>}
                </div>
            </div>
            <div className={styles.slideDataBox}>
                <div className={styles.slideDataBox_infoContent}>
                    {slide.paragraph4 && <p className={styles.slideDataBox_infoContent_txt}>{slide.paragraph4}</p>}
                    {
                        slide.modalImg &&
                        <div className={styles.cardBoxPicture}>
                            <button
                                className={styles.cardBoxPictureBtn}
                                onClick={handleOpenImgModal}
                                onKeyDown={(e) => e.key == "Enter" && handleOpenImgModal}
                                role="button"
                                aria-label="Otwórz"
                                tabIndex={0}
                            />
                            <p className={styles.author}>{slide?.modalImg?.modalImgAlt}</p>
                        </div>
                    }
                </div>
                <div className={styles.slideChart} tabIndex={0} aria-label="Wykres">
                    <h2>{slide.chart?.title}</h2>
                    {
                        slide.sourceModal &&
                        <button
                            className={styles.sourceButton}
                            onClick={handleOpenSourceModal}
                            onKeyDown={(e) => e.key == "Enter" && handleOpenSourceModal}
                        >
                            <div className={styles.sourceButton_icon}>i</div>
                            Żródło
                        </button>
                    }
                    {(keepVisible || isActiveSlide) &&
                    <Charts
                        tickCount={slide?.chart?.tickCount}
                        data={slide?.chart?.data}
                        bar={slide?.chart?.bar}
                        legend={slide?.chart?.legend}
                        label={slide?.chart?.label}
                        isVertical={slide?.chart?.isVertical}
                        isVerticalOnMobile={slide?.chart?.isVerticalOnMobile}
                    />}
                </div>
            </div>
            <BottomPanel
                artifacts={slide.artifactsData}
                tasks={slide.tasksData}
                slide={slide.slideSymbol}
            />
            <NextChapterBtn currentSlide={currentSlide} />
        </div>
    </div>
  );
}

export default SlideT13;