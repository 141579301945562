import styles from "./Charts.module.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts';
import CustomLegend from "./CustomLegend";
import CustomXAxisTick from "./CustomXAxisTick";
import CustomLabelList from "./CustomLabelList";
import { useEffect, useState } from "react";
import { formatTick } from "../../utils";

type Props = {
  tickCount?: number;
  data: any;
  bar: any;
  legend: any;
  label: any;
  isVertical?: boolean;
  isVerticalOnMobile?: boolean;
}

const Charts: React.FC<Props> = ({
  tickCount = 10,
  data,
  bar,
  legend,
  label,
  isVertical = false,
  isVerticalOnMobile
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  if (!data.length) {
    return <div></div>
  }

  const dataKeys = Object.keys(data[0]).filter(y => !y.includes("name"));

  const isMobileAndVertical = isVertical || (windowWidth < 992 && isVerticalOnMobile);

  if (isMobileAndVertical) {
    return (
      <div className={`${styles.verticalWrapper} ${label?.icons?.length ? styles.verticalWrapperLabelIcons : ""}`}>
        <ResponsiveContainer>
          <BarChart data={data} layout="vertical">
            <CartesianGrid
              stroke="rgba(217, 217, 217, 0.1)"
              strokeDasharray="0"
              vertical={true}
              horizontal={false}
            />
            <XAxis
              type="number"
              className={styles.labelHidden}
            />
            <YAxis
              tickCount={tickCount}
              tickFormatter={tick => label?.isFormattedValue ? formatTick(tick) : tick >= 1000 ? tick / 1000 : tick}
              className={styles.xLabel}
              type="category"
              dataKey="name"
              tick={
                <CustomXAxisTick
                  labelIcons={label?.icons}
                  isVertical={isMobileAndVertical}
                />
              }
            />
            {legend &&
              <Legend
                content={
                  <CustomLegend
                    legendPosition={legend?.position}
                    icons={legend?.icons}
                    labels={legend?.label}
                    barColors={bar?.colors}
                  />
                }
                wrapperStyle={{
                  position: "static",
                  width: "222px",
                }}
              />
            }
            {dataKeys.map((x: any, i: number) => {
              const name = x.name;
              return (
                <Bar
                  name={name}
                  dataKey={x}
                  fill={bar.colors[i]}
                  key={i}
                  animationDuration={1500}
                  barSize={30}
                >
                  <LabelList
                    position="right"
                    formatter={(tick: number) => bar?.isFormattedValue ? formatTick(tick, bar?.customFormat) : tick}
                    className={styles.barLabel}
                    width={100}
                  />
                  {bar.isLabelBelowBar &&
                    <LabelList
                      dataKey="name"
                      content={<CustomLabelList isLabelBelowBar={bar.isLabelBelowBar} />}
                    />
                  }
                </Bar>
              )
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <ResponsiveContainer>
        <BarChart data={data} barGap={14}>
          <CartesianGrid
            stroke="rgba(217, 217, 217, 0.1)"
            strokeDasharray="0"
            vertical={false}
          />
          <XAxis
            dataKey="name"
            className={styles.xLabel}
            interval={0}
            tickLine={false}
            tick={
              <CustomXAxisTick
                labelIcons={label?.icons}
                isVertical={isMobileAndVertical}
              />}
          />
          <YAxis
            tickCount={tickCount}
            tickFormatter={tick => label?.isFormattedValue ? formatTick(tick) : tick >= 1000 ? tick / 1000 : tick}
            className={styles.yLabel}
            width={80}
          />
          {legend &&
            <Legend
              content={
                <CustomLegend
                  legendPosition={legend?.position}
                  icons={legend?.icons}
                  labels={legend?.labels}
                  barColors={bar?.colors}
                />
              }
              wrapperStyle={{
                position: "static",
                width: "222px",
              }}
            />
          }
          {dataKeys.map((x: any, i: number) => {
            const name = x.name;
            return (
              <Bar
                name={name}
                dataKey={x}
                fill={bar?.colors[i]}
                key={i}
                animationDuration={1500}
                barSize={30}
              >
                <LabelList
                  position="top"
                  formatter={(tick: number) => bar?.isFormattedValue ? formatTick(tick, bar?.customFormat) : tick}
                  className={styles.barLabel}
                  dy={-5}
                  width={100}
                />
              </Bar>
            )
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Charts