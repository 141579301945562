import { ReactNode } from "react";
import TopMenu from "./TopMenu";
import AsideMenu from "./AsideMenu";
import styles from "./Layout.module.scss";

type Props = {
  children: ReactNode;
  slidesQuantity: number[];
  chapterTitles: { chapter: string, name: string }[];
  activeSlideId: number;
}

const Layout: React.FC<Props> = ({
  children,
  slidesQuantity,
  chapterTitles,
  activeSlideId
}) => {
  return (
    <>
      <header className={styles.header}>
        <TopMenu slidesQuantity={slidesQuantity} chapterTitles={chapterTitles} />
      </header>
      <AsideMenu
        activeSlideId={activeSlideId}
        slidesQuantity={slidesQuantity}
      />
      <main className={styles.main}>
        {children}
      </main>
    </>
  );
}

export default Layout;