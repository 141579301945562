export const sumValuesFromArray = (arr: number[]) => {
  return arr.reduce(function (previousValue: number, currentValue: number) {
    return previousValue + currentValue;
  });
};

export const formatTick = (tick: number, customFormat?: string) => {
  if (tick === 0) {
    return tick;
  }

  if (customFormat) {
    return `${tick} ${customFormat}`;
  }

  if (tick >= 1000000) {
    return `${(tick / 1000 / 1000).toString().replace(".", ",")} MLN.`;
  } if (tick < 1000000 && tick >= 1000) {
    return `${(tick / 1000).toString().replace(".", ",")} TYS.`;
  } else {
    return tick;
  }
}

export const isLastSlide = (arr:number[], index:number) => {
  const finalValue = 0;
  const findLast = arr.reduce((x, y) => x + y, finalValue);

  return index === findLast;
}