import { FC, useContext } from 'react';
import styles from "./SlideT12.module.scss";
import TextBanner from '../../components/common/TextBanner';
import NextChapterBtn from '../../components/common/NextChapterBtn';
import BottomPanel from '../../components/BottomPanel';
import { ChapterContext } from '../../App';
import ArticleBox from '../../components/ArticleBox';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { spawn } from 'child_process';

type Props = {
  slide: any;
  currentSlide: number;
}

const SlideT2: FC<Props> = ({ slide, currentSlide }) => {
  const context = useContext(ChapterContext)
  const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
  return (
    <div className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
      <div className={styles.slideWrapper}>
        <div className={styles.slideImageBox} tabIndex={0}>
          {
            slide.slideImg &&
            <span className={styles.slideImage}>
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImg}
              />
            </span>
          }
          {
            slide.slideImgMobile &&
            <span className={styles.slideImageMobile}>
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImgMobile}
              />
            </span>
          }
          <span className={styles.absoluteBanner}>
            <TextBanner text={slide.bannerText} />
          </span>
        </div>
        <div className={styles.slideDataBox}>
          <ArticleBox slide={slide} />
        </div>
        <div className={styles.slideImageBox} tabIndex={0}>
          {
            slide.slideImg2 &&
            <span className={styles.slideImage}>
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImg2}
              />
            </span>
          }
          {
            slide.slideImgMobile2 &&
            <span className={styles.slideImageMobile}>
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImgMobile2}
              />
            </span>
          }
          <span className={styles.absoluteBanner2}>
            <TextBanner text={slide.bannerText2} />
          </span>
        </div>
        <BottomPanel
          artifacts={slide.artifactsData}
          tasks={slide.tasksData}
          slide={slide.slideSymbol}
          />
        <NextChapterBtn currentSlide={currentSlide} />
      </div>
    </div>
  );
}

export default SlideT2;