import React, { useState } from "react";
import styles from "./Intro.module.scss";
import ReactPlayer from 'react-player/lazy'

type Props = {
  setIsIntro: React.Dispatch<any>;
}

const Intro: React.FC<Props> = ({ setIsIntro }) => {
  const [isVideo, setIsVideo] = useState(false);

  const handleCloseIntro = () => {
    localStorage.setItem("wielkie-historie-intro", "skipped");
    setIsIntro(false);
  }

  if (isVideo) return (
    <section className={styles.container}>
      <div className={styles.video}>
        <div className={styles.videoWrapper}>
          <div className={styles.videoPlayer}>
            <ReactPlayer
              url="/assets/video/intro.mp4"
              width="100%"
              height="100%"
              playing={true}
              onEnded={() => setIsIntro(false)}
            />
          </div>
        </div>
      </div>
      <button
        className={styles.button}
        onClick={handleCloseIntro}
      >
        Pomiń intro
      </button>
    </section>
  )
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <img src="/assets/logo.png" alt="" className={styles.logo} />
          <button
            className={styles.button}
            onClick={() => setIsVideo(true)}
          >
            Wejdź do muzeum
          </button>
        </div>
        <img src="/assets/logo_watermark.png" alt="" />
      </div>
    </section>
  )
}

export default Intro