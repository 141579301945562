import { FC, useContext, useEffect, useState } from 'react';
import TextBanner from '../../components/common/TextBanner';
import Charts from '../../components/Charts';

import styles from './SlideT5.module.scss';
import NextChapterBtn from '../../components/common/NextChapterBtn';
import BottomPanel from '../../components/BottomPanel';
import { ChapterContext } from '../../App';

type Props = {
    slide: any;
    currentSlide: number;
}

const SlideT5: FC<Props> = ({ slide, currentSlide }) => {
    const context = useContext(ChapterContext)
    const isActiveSlide = parseInt(slide.slideSymbol.split(".")[0]) === context.activeChapter && parseInt(slide.slideSymbol.split(".")[1]) == currentSlide;
    const [keepVisible, setKeepVisible] = useState(true);

    setTimeout(() => isActiveSlide ? setKeepVisible(true) : setKeepVisible(false), 1000);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const isNextSlide = parseInt(slide.slideSymbol.split(".")[0]) < context.slidesQuantity[context.activeChapter - 1];
    return (
        <section className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
            <div className={`${styles.slideWrapper} ${slide.withoutBanner ? styles.changeLayout : ''}`}>
                <NextChapterBtn currentSlide={currentSlide} />
                <BottomPanel
                    artifacts={slide.artifactsData}
                    tasks={slide.tasksData}
                    slide={slide.slideSymbol}
                />
                <div className={styles.slideChartBox} tabIndex={0} aria-label="Wykres">
                    <header className={styles.slideBoxHeader}>
                        <p>{slide?.chart?.title}</p>
                        {slide?.chart?.desc && <span>{slide?.chart?.desc}</span>}
                    </header>
                    <div className={styles.slideChart}>
                        {(keepVisible || isActiveSlide) &&
                            <Charts
                                tickCount={slide?.chart?.tickCount}
                                data={slide?.chart?.data}
                                bar={slide?.chart?.bar}
                                legend={slide?.chart?.legend}
                                label={slide?.chart?.label}
                                isVertical={slide?.chart?.isVertical}
                                isVerticalOnMobile={slide?.chart?.isVerticalOnMobile}
                            />
                        }
                    </div>
                </div>
                {slide.withoutBanner &&
                    <div className={styles.slideBox} tabIndex={0}>
                        <h2>{slide.title}</h2>
                        <p>{slide.paragraph1}</p>
                        {slide.paragraph2 && <p>{slide.paragraph2}</p>}
                    </div>
                }
                {!slide.withoutBanner &&
                    <>
                        <div className={styles.slideBanner} tabIndex={0}>
                            <TextBanner text={slide.bannerText} />
                        </div>
                        <div className={styles.mobileImg} style={{ backgroundImage: `url(${slide.slideImgMobile})` }} />
                    </>
                }
            </div>
        </section>
    );
}

export default SlideT5;