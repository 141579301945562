import { FC, useContext } from "react";
import { ChapterContext } from "../../../App";
import styles from "./TasksButton.module.scss";

type Props = {
  data: any;
  slide: string;
};

const TasksButton: FC<Props> = ({ data, slide }) => {
  const context = useContext(ChapterContext);

  const tasksLength = data?.tasks?.length;

  const handleClick = () => {
    context.setModalData({ type: "task", data: data })
  };

  if (!tasksLength) {
    return (
      <div className={styles.footerBox}>
        <div className={styles.footerBoxLight} />
        <button
          disabled
          aria-label="Nieaktywny przycisk zadań"
          className={styles.footerBoxBtn}
        >
        </button>
        <p className={styles.activeSlideOnMobile}>{slide}</p>
        <p className={styles.footerBoxTxt}>
          Zadania
        </p>
      </div>
    )
  }

  return (
    <div className={styles.footerBox}>
      <div className={styles.footerBoxLight} />
      <button
        className={`${styles.footerBoxBtn} ${styles.btnIconActive}`}
        onClick={handleClick}
        onKeyDown={(e) => e.key == "Enter" && handleClick}
        tabIndex={0}
        role="button"
        aria-label="Zadania"
      >
        <div className={styles.tasks}>{tasksLength}</div>
      </button>
      <p className={styles.activeSlideOnMobile}>{slide}</p>
      <p className={styles.footerBoxTxt}>
        Zadania
      </p>
    </div>
  );
};

export default TasksButton;
