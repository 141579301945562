import styles from "./CustomLegend.module.scss";

const CustomLegend = (props: any) => {
  const { legendPosition, labels, icons, barColors } = props;
  const className = legendPosition === "top" ? "legendTop" : "legendBottom";

  if (!labels?.length) return;
  return (
    <div className={`${styles.legend} ${styles[className]}`}>
      {labels?.map((x: any, i: number) => (
        <div className={styles.legendWrapper} key={i}>
          {icons?.length ?
            <div
              className={styles.legendIcon}
              style={{ backgroundImage: `url(${icons[i]})` }}
            /> :
            <div className={styles.legendSquare} style={{ backgroundColor: barColors[i] }} />
          }
          <p className={styles.legendTitle}>{x}</p>
        </div>
      ))}
    </div>
  )
}

export default CustomLegend