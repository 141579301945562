import { FC } from "react";
import FocusLock from 'react-focus-lock';

import styles from "./SourceModal.module.scss";

interface Props {
    data: any;
    closeModal: () => void;
}

const SourceModal:FC<Props> = ({data, closeModal}) => {
    return (
        <FocusLock returnFocus={{ preventScroll: false }} >
            <div
                className={styles.modalBg}
                onClick={() => {
                    closeModal();
                }}
            >
                <article className={styles.modalBorder}
                    onClick={(event) => {
                        event?.stopPropagation();
                    }}
                >
                    <div className={styles.modalWindow}>
                        <div className={styles.modalTextBox}>
                            <h1 className={styles.header}>
                                Żródła
                                <div className={styles.infoIcon}>i</div>
                            </h1>
                            { data.text && 
                                <p className={styles.infoText}>
                                    {data.text}
                                </p>
                            }
                            { data.links && 
                                <ul>
                                    {
                                        data.links.map((x:string, id:number) => {
                                            return (
                                                <li key={id}>
                                                    <a className={styles.infoLink} href={`${x}`}>{x}</a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            }
                        </div>
                        <button
                            onClick={() => closeModal()}
                            className={styles.closeBtn}
                            onKeyDown={(e) => e.key == "Enter" && closeModal()}
                            role="button"
                            aria-label="Zamknij"
                            tabIndex={0}
                        />
                    </div>
                    <span className={`${styles.dot} ${styles.dot1}`} />
                    <span className={`${styles.dot} ${styles.dot2}`} />
                    <span className={`${styles.dot} ${styles.dot3}`} />
                    <span className={`${styles.dot} ${styles.dot4}`} />
                </article>
            </div>
        </FocusLock>
    );
}
 
export default SourceModal;