import ReactPlayer from 'react-player/lazy'
import styles from "./AboutMuseum.module.scss";
import { aboutMuseumData } from "./data";

const AboutMuseum = () => (
  <div>
    <section className={styles.container}>
      <h2 className={styles.header}>{aboutMuseumData.header}</h2>
      <div className={styles.video}>
        <div className={styles.videoWrapper}>
          <div className={styles.videoPlayer}>
            <ReactPlayer
              url={aboutMuseumData.video}
              width="100%"
              height="100%"
              controls
            />
          </div>
        </div>
      </div>
      {aboutMuseumData.content.map((x, i: number) => (
        <div className={styles.content} key={i}>
          <h3 className={styles.category}>{x.category}</h3>
          <div
            className={styles.desc}
            dangerouslySetInnerHTML={{ __html: x.desc }}>

          </div>
        </div>
      ))}
    </section>
    <section className={styles.partners}>
      <div className={styles.top}>
        <div className={styles.topContent}>
          {aboutMuseumData.partners.top.logos.map((x, i: number) => (
            <img src={x.src} alt={x.name} key={i} />
          ))}
          <p className={styles.topDesc}>{aboutMuseumData.partners.top.text}</p>
        </div>
      </div>
      <div className={styles.partnersWrapper}>
        <div
          className={styles.partnersDesc}
          dangerouslySetInnerHTML={{ __html: aboutMuseumData.partners.description }}>

        </div>
        <ul className={styles.main}>
          {aboutMuseumData.partners.main.map((x, i: number) => (
            <li className={styles.mainBlock} key={i}>
              <p className={styles.partnersCategory}>{x.category}</p>
              <img src={x.logo} alt={x.name} />
              {x.text && <span className={styles.partnersInfo}>{x.text}</span>}
            </li>
          ))}
        </ul>
        <p className={styles.partnersCategory}>{aboutMuseumData.partners.partnersList.header}</p>
        <ul className={styles.partnersList}>
          {aboutMuseumData.partners.partnersList.logos.map(x => (
            <li className={styles.partnersListItem}>
              <img src={x.src} alt={x.name} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  </div>
)

export default AboutMuseum