import React, { useContext } from "react";
import { useState } from "react";
import { ChapterContext } from "../../../App";
import styles from "./TopMenu.module.scss";
import ChapterLink from "./ChapterLink";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FocusLock from 'react-focus-lock';

type Props = {
  slidesQuantity: number[];
  chapterTitles: { chapter: string, name: string }[];
}

const TopMenu: React.FC<Props> = ({ slidesQuantity, chapterTitles }) => {
  const [burgerOpen, setBurgerOpen] = useState<boolean>(false);
  const context = useContext(ChapterContext);

  const activeChapterDetails = chapterTitles[context.activeChapter - 1];

  return (
    <nav className={styles.navigation}>
      <div className={styles.navigationFirstBox}>
        <button
          className={styles.burgerBtn}
          onClick={() => setBurgerOpen(true)}
          onKeyDown={(e) => e.key == "Enter" && setBurgerOpen(true)}
          role="button"
          aria-label="Otwórz menu"
          tabIndex={0}
        >
          <span className={styles.btnBar} />
        </button>
        {burgerOpen &&
          <FocusLock returnFocus={{ preventScroll: false }}>
            <div className={styles.burgerMenu}>
              <button
                className={`${styles.burgerBtn} ${styles.burgerBtnClose} ${styles.burgerBtnActive}`}
                onClick={() => setBurgerOpen(false)}
                onKeyDown={(e) => e.key == "Enter" && setBurgerOpen(false)}
                role="button"
                aria-label="Zamknij menu"
                tabIndex={0}
              >
                <span className={styles.btnBar} />
              </button>
              <div className={styles.burgerMenuContainer}>
                <div className={styles.burgerMenuListWrapper}>
                  <ul className={styles.burgerMenuList} role="menu">
                    {slidesQuantity.map((x, index) => (
                      <React.Fragment key={index}>
                        <ChapterLink
                          slidesQuantity={slidesQuantity}
                          index={index}
                          chapterTitles={chapterTitles}
                          setBurgerOpen={setBurgerOpen}
                        />
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={styles.helpfullLinks}>
                <button
                  className={styles.link}
                  onClick={() => context.setModalWrapperType("aboutMuseum")}
                >
                  O muzeum
                </button>
                <button
                  className={styles.link}
                  onClick={() => context.setModalWrapperType("authors")}
                >
                  Autorzy
                </button>
                <button
                  className={styles.link}
                  onClick={() => context.setModalWrapperType("education")}
                >
                  Materiały edukacyjne
                </button>
                <a 
                  href="https://archiwum.tajnepanstwo.pl/"
                  target="_blank"
                  className={styles.link}
                >
                  Repozytorium zbiorów
                </a>
              </div>
            </div>
          </FocusLock>
        }
        <div className={styles.navigationData} tabIndex={0}>
          <p className={styles.chapter}>
            {activeChapterDetails?.chapter}
          </p>
          <p>
            {activeChapterDetails?.name}
          </p>
        </div>
      </div>
    </nav>
  );
}

export default TopMenu;