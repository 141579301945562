import { useContext, useRef, useState } from "react";
import { ChapterContext } from "../../../App";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from "./Player.module.scss";
import PlayPauseButton from "../../icons/PlayPauseButton";
import ReactPlayer from 'react-player/lazy'

const formatTime = (seconds: number) => {
  if (typeof seconds === 'number') {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
      .filter(Boolean)
      .join(":");
  }
  return ''
}

const Player = () => {
  const [progress, setProgress] = useState({
    currentTime: 0,
    currentProgress: 0
  });
  const [trackTime, setTrackTime] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(false);
  const context = useContext(ChapterContext);

  const playerRef = useRef(null);

  const handlePlayerProgress = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const percentage = e.nativeEvent.offsetX / rect.width;

    if (playerRef.current) {
      try {
        // @ts-ignore
        playerRef?.current?.seekTo(isNaN(percentage) ? 0 : percentage);
      } catch (error) {
        console.log(error)
      }
    }
  }

  if (context.modalData.type === "video") {
    return (
      <div className={styles.video}>
        <div className={styles.videoWrapper}>
          <div className={styles.videoPlayer}>
            <ReactPlayer
              ref={playerRef}
              playing={isPlaying}
              url={context.modalData?.data?.file}
            />
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <LazyLoadImage
        alt="Ikonka"
        effect="blur"
        src="/assets/audio_cover.webp"
        className={`${styles.image} ${isPlaying ? styles.animate : ''}`}
      />
      <div className={styles.icon} onClick={() => setIsPlaying(!isPlaying)}>
        <PlayPauseButton playing={isPlaying} />
        <ReactPlayer
          ref={playerRef}
          style={{ display: "none" }}
          config={{
            file: {
              forceAudio: true,
            },
          }}
          playing={isPlaying}
          url={context.modalData?.data?.file}
          onDuration={(time) => setTrackTime(time)}
          onProgress={(progress) => setProgress({
            currentTime: progress?.playedSeconds,
            currentProgress: progress?.played * 100
          })}
          onError={() => setIsPlaying(false)}
          onEnded={() => setIsPlaying(false)}
          width={0}
          height={0}
        />
      </div>
      <div className={styles.progress}>
        {progress.currentTime >= 0 && <p className={styles.time}>{formatTime(progress.currentTime)}</p>}
        <div
          onClick={(e: React.MouseEvent) => handlePlayerProgress(e)}
          style={{
            background: `linear-gradient(to right, #ff4337 ${progress?.currentProgress}%, #16171A ${progress?.currentProgress}%)`,
          }}
          className={styles.progressBar}
        >

        </div>
        {trackTime > 0 && <p className={styles.time}>{formatTime(trackTime)}</p>}
      </div>
    </>
  );
}

export default Player;
