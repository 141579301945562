import { FC, useContext } from 'react';
import BottomPanel from '../../components/BottomPanel';
import TextBanner from '../../components/common/TextBanner';
import NextChapterBtn from '../../components/common/NextChapterBtn';
import { ChapterContext } from '../../App';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SlideT8.module.scss';
import ReactPlayer from 'react-player/lazy'
import ArticleBox from '../../components/ArticleBox';

type Props = {
  slide: any;
  currentSlide: number;
}

const SlideT8: FC<Props> = ({ slide, currentSlide }) => {
  const context = useContext(ChapterContext);
  const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
  return (
    <section className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
      <div className={`${styles.slideWrapper} ${slide.reversed ? styles.rowRewerse : ''}`}>
        <NextChapterBtn currentSlide={currentSlide} />
        <BottomPanel
          artifacts={slide.artifactsData}
          tasks={slide.tasksData}
          slide={slide.slideSymbol}
        />
        <>
          <div className={styles.video} tabIndex={0}>
            <div className={styles.videoWrapper}>
              <div className={styles.videoPlayer}>
                <ReactPlayer
                  url={slide?.video}
                  width="100%"
                  height="100%"
                  controls
                />
              </div>
            </div>
            <div className={styles.slideVideoText}>
              <TextBanner text={slide.bannerText} />
            </div>
          </div>
          <ArticleBox slide={slide} />
          <div className={styles.slideImageBox} tabIndex={0}>
            {
              slide.slideImgMobile &&
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImgMobile}
                className={styles.slideImageMobile}
              />
            }
            {
              slide.slideImg &&
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImg}
                className={styles.slideImage}
              />
            }
            <span className={styles.absoluteBanner}>
              <TextBanner text={slide.bannerText2} />
            </span>
          </div>
        </>
      </div>
    </section >
  );
}

export default SlideT8;