import { Dispatch, SetStateAction, useEffect } from "react";

import {
  SlideT1,
  SlideT2,
  SlideT3,
  SlideT4,
  SlideT5,
  SlideT6,
  SlideT7,
  SlideT8,
  SlideT9,
  SlideT10,
  SlideT11,
  SlideT12,
  SlideT13
} from "../../Views";

type Props = {
  currentSlide: number;
  chapter: any;
  setSlidesQuantity: Dispatch<SetStateAction<number[]>>;
  slidesQuantity: number[];
  chapterTitles: { chapter: string, name: string }[];
  setChapterTitles: Dispatch<SetStateAction<{ chapter: string, name: string }[]>>;
}

const Chapter: React.FC<Props> = ({
  currentSlide,
  chapter,
  slidesQuantity,
  setSlidesQuantity,
  chapterTitles,
  setChapterTitles,
}) => {
  let slideVisible: any;

  useEffect(() => {
    if (slidesQuantity.length) return;
    setSlidesQuantity(arr => [...arr, chapter.slides.length]);

    if (chapterTitles.length) return;
    setChapterTitles(arr => [...arr, { chapter: chapter.chapter, name: chapter.chapterName }]);
  }, [])

  return (
    <>
      {chapter.slides.map((x: any, index: number) => {
        switch (x.slideType) {
          case 'T1':
            slideVisible = (
              <SlideT1 chapter={chapter} slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T2':
            slideVisible = (
              <SlideT2 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T3':
            slideVisible = (
              <SlideT3 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T4':
            slideVisible = (
              <SlideT4 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T5':
            slideVisible = (
              <SlideT5 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T6':
            slideVisible = (
              <SlideT6 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T7':
            slideVisible = (
              <SlideT7 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T8':
            slideVisible = (
              <SlideT8 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T9':
            slideVisible = (
              <SlideT9 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T10':
            slideVisible = (
              <SlideT10 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T11':
            slideVisible = (
              <SlideT11 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T12':
            slideVisible = (
              <SlideT12 slide={x} currentSlide={currentSlide} />
            )
            break
          case 'T13':
            slideVisible = (
              <SlideT13 slide={x} currentSlide={currentSlide} />
            )
            break
          default:
            break
        }
        return (
          <section
            key={index}
            data-chapter-id={chapter.id}
            data-slide-id={index + 1}
            className="section"
          >
            {slideVisible}
          </section>
        )
      })}
    </>
  )
}

export default Chapter;