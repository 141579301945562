import React, { FC } from "react";

interface Props {
  size?: number;
  fill?: string;
  stroke?: string
  playing?: boolean
}

const PlayPauseButton: FC<Props> = ({
  size = 38,
  fill = "#E11838",
  stroke = "#1E1F23",
  playing = false,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 38 38"
    >
      {!playing ? (
        <>
          <circle cx="19" cy="19" r="17" fill={fill} />
          <path
            d="M23.5 18.134C24.1667 18.5189 24.1667 19.4811 23.5 19.866L17.5 23.3301C16.8333 23.715 16 23.2339 16 22.4641L16 15.5359C16 14.7661 16.8333 14.285 17.5 14.6699L23.5 18.134Z"
            fill={stroke}
          />
        </>
      ) : (
        <>
          <circle cx="19" cy="19" r="17" fill={fill} />
          <path d="M16 15L16 23" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
          <path d="M22 15L22 23" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
        </>
      )}
    </svg>
  );
}

export default PlayPauseButton;
