import { FC, useContext, useState } from 'react';
import Charts from '../../components/Charts';
import styles from "./SlideT10.module.scss";
import NextChapterBtn from '../../components/common/NextChapterBtn';
import BottomPanel from '../../components/BottomPanel';
import { ChapterContext } from '../../App';
import ArticleBox from '../../components/ArticleBox';

type Props = {
  slide: any;
  currentSlide: number;
}

const SlideT10: FC<Props> = ({ slide, currentSlide }) => {
  const context = useContext(ChapterContext)
  const isActiveSlide = parseInt(slide.slideSymbol.split(".")[0]) === context.activeChapter && parseInt(slide.slideSymbol.split(".")[1]) == currentSlide;
  const [keepVisible, setKeepVisible] = useState(true);

  setTimeout(() => isActiveSlide ? setKeepVisible(true) : setKeepVisible(false), 1000);

  const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
  return (
    <div className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
      <div className={styles.slideWrapper}>
        <div className={styles.slideDataBox}>
          <div className={styles.slideChart} tabIndex={0} aria-label="Wykres">
            <h2>{slide?.chart?.title}</h2>
            {(keepVisible || isActiveSlide) &&
              <div className={styles.slideChartWrapper}>
                <div className={styles.chart1}>
                  <Charts
                    tickCount={slide?.chart?.tickCount}
                    data={slide?.chart?.data}
                    bar={slide?.chart?.bar}
                    legend={slide?.chart?.legend}
                    label={slide?.chart?.label}
                    isVertical={slide?.chart?.isVertical}
                    isVerticalOnMobile={slide?.chart?.isVerticalOnMobile}
                  />
                </div>
                <div className={styles.chart2}>
                  <Charts
                    tickCount={slide?.chart2?.tickCount}
                    data={slide?.chart2?.data}
                    bar={slide?.chart2?.bar}
                    legend={slide?.chart2?.legend}
                    label={slide?.chart?.label}
                    isVertical={slide?.chart2?.isVertical}
                    isVerticalOnMobile={slide?.chart2?.isVerticalOnMobile}
                  />
                </div>
              </div>
            }
          </div>
          <ArticleBox slide={slide}/>
        </div>
        <BottomPanel
          artifacts={slide.artifactsData}
          tasks={slide.tasksData}
          slide={slide.slideSymbol}
        />
        <NextChapterBtn currentSlide={currentSlide} />
      </div>
    </div>
  );
}

export default SlideT10;