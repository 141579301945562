import { FC } from "react";
import styles from "./EducationMaterials.module.scss";

const EducationMaterials:FC = () => {

    const links = [
        {
            id: 1,
            name: 'PPP scenariusze archiwa',
            link: 'assets/files/PPP_scenariusze_archiwa.pdf'
        },
        {
            id: 2,
            name: 'PPP scenariusze Baczyński',
            link: 'assets/files/PPP_scenariusze_baczynski.pdf'
        },
        {
            id: 3,
            name: 'PPP scenariusze kobiety',
            link: 'assets/files/PPP_scenariusze_kobiety.pdf'
        },
        {
            id: 4,
            name: 'PPP scenariusze Szare Szeregi',
            link: 'assets/files/PPP_scenariusze_szare_szeregi.pdf'
        }
    ]

    return (
        <section className={styles.container}>
            <h2 className={styles.header}>Materiały edukacyjne</h2>
            <ul className={styles.material_list}>
                {
                    links.map(x => {
                        return(
                            <li 
                                key={x.id}
                                className={styles.material_link}
                            >
                                <a href={x.link} download>{x.name}</a>
                            </li>
                        )
                    })
                }
            </ul>
        </section>
    );
}
 
export default EducationMaterials;