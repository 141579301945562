import { FC, useContext } from "react";
import TextBanner from "../../components/common/TextBanner";
import NextChapterBtn from "../../components/common/NextChapterBtn";
import { ChapterContext } from "../../App";
import BottomPanel from "../../components/BottomPanel";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from "./SlideT1.module.scss";

type Props = {
    chapter: any;
    slide: any;
    currentSlide: number;
}

const SlideT1: FC<Props> = ({ chapter, slide, currentSlide }) => {
    const context = useContext(ChapterContext);
    const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];

    return (
        <div className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
            <div className={styles.titleWrapper}>
                <div className={styles.titleImgBox} tabIndex={0}>
                    {
                        slide.slideImg &&
                        <LazyLoadImage
                            alt={slide.slideImgAlt}
                            effect="blur"
                            src={slide.slideImg}
                            className={`${styles.titleImg}`}
                        />
                    }
                    {
                        slide.slideImgMobile &&
                        <LazyLoadImage
                            alt={slide.slideImgAlt}
                            effect="blur"
                            src={slide.slideImgMobile}
                            className={`${styles.titleImgMobile}`}
                    />
                    }
                    {
                        slide.bannerText &&
                        <div className={styles.titleImgBanner}>
                            <TextBanner text={slide.bannerText} />
                        </div>
                    }
                </div>
                <div className={styles.titleBox} tabIndex={0}>
                    <p className={styles.titleChapter}>{chapter.chapter}</p>
                    <h2 className={styles.titleHeading}>{chapter.chapterName}</h2>
                    <p className={styles.titleTextContent}>
                        {slide.title}
                    </p>
                    <div className={styles.slideDesc}>
                        <p className={styles.slideDescContent}>{slide.description}</p>
                    </div>
                </div>
                <BottomPanel
                    artifacts={slide.artifactsData}
                    tasks={slide.tasksData}
                    slide={slide.slideSymbol}
                />
                <NextChapterBtn currentSlide={currentSlide} />
            </div>
        </div>
    );
}

export default SlideT1;