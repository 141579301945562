import { FC, useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SlideT3.module.scss';
import { ChapterContext } from '../../App';

interface Props {
    img: string,
    modalImg: string,
    quote: string,
    name: string,
    yol: string,
    info: string
}

const PersonCard: FC<Props> = ({ img, modalImg, quote, name, yol, info }) => {
    const context = useContext(ChapterContext);

    const handleOpenModal = () => {
        context.setModalData({
            type: "person",
            data: {
                name: name,
                modalImg: modalImg,
                yol: yol,
                info: info
            }
        })
    }

    return (
        <li className={styles.cardBox} role="presentation" tabIndex={0}>
            <div className={styles.cardBoxImg}>
                <LazyLoadImage
                    alt={name}
                    effect="blur"
                    src={img}
                />
                <span className={`${styles.dot} ${styles.dot1}`} aria-hidden="true" />
                <span className={`${styles.dot} ${styles.dot2}`} aria-hidden="true" />
                <span className={`${styles.dot} ${styles.dot3}`} aria-hidden="true" />
                <span className={`${styles.dot} ${styles.dot4}`} aria-hidden="true" />
            </div>
            <div>
                <div aria-hidden="true" className={styles.quoteSymbol} />
                <p className={styles.quote}>{quote}</p>
            </div>
            <div className={styles.cardBoxAuthor}>
                <button
                    className={styles.cardBoxAuthorBtn}
                    onClick={handleOpenModal}
                    onKeyDown={(e) => e.key == "Enter" && handleOpenModal}
                    role="button"
                    aria-label="Otwórz"
                    tabIndex={0}
                />
                <p className={styles.author}>{name}</p>
            </div>
        </li>
    );
}

export default PersonCard;