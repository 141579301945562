import { FC, useContext, useState } from 'react';
import Charts from '../../components/Charts';
import styles from "./SlideT2.module.scss";
import TextBanner from '../../components/common/TextBanner';
import NextChapterBtn from '../../components/common/NextChapterBtn';
import BottomPanel from '../../components/BottomPanel';
import { ChapterContext } from '../../App';
import ArticleBox from '../../components/ArticleBox';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  slide: any;
  currentSlide: number;
}

const SlideT2: FC<Props> = ({ slide, currentSlide }) => {
  const context = useContext(ChapterContext)
  const isActiveSlide = parseInt(slide.slideSymbol.split(".")[0]) === context.activeChapter && parseInt(slide.slideSymbol.split(".")[1]) == currentSlide;
  const [keepVisible, setKeepVisible] = useState(true);



  const handleOpenPersonModal = () => {
    context.setModalData({
      type: "person",
      data: {
        name: slide.modalName,
        modalImg: slide.modalImg,
        modalImgDesc: slide.modalImgDesc,
        yol: slide.modalYol,
        info: slide.modalInfo
      }
    })
  }

  const handleOpenSourceModal = () => {
    context.setModalData({
      type: "source",
      data: slide.sourceModal,
    })
  }

  setTimeout(() => isActiveSlide ? setKeepVisible(true) : setKeepVisible(false), 1000);

  const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
  return (
    <div className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
      <div className={styles.slideWrapper}>
        <div className={styles.slideImageBox} tabIndex={0}>
          {
            slide.slideImgMobile &&
            <span className={styles.imgMobile}>
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImgMobile}
                
              />
            </span>
          }
          {
            slide.slideImg &&
            <LazyLoadImage
              alt={slide.slideImgAlt}
              effect="blur"
              src={slide.slideImg}
              className={styles.slideImage}
            />
          }
          {
            slide.modalName &&
            <div className={styles.cardBoxAuthor}>
              <button
                className={styles.cardBoxAuthorBtn}
                onClick={handleOpenPersonModal}
                onKeyDown={(e) => e.key == "Enter" && handleOpenPersonModal}
                role="button"
                aria-label="Otwórz"
                tabIndex={0}
              />
              <p className={styles.author}>{slide.modalName}</p>
            </div>
          }
          <span className={styles.absoluteBanner}>
            <TextBanner text={slide.bannerText} />
          </span>
        </div>
        <div className={styles.slideDataBox}>
          <ArticleBox slide={slide} />
          <div className={styles.slideChart} tabIndex={0} aria-label="Wykres">
            <h2>{slide.chart?.title}</h2>
            {
              slide.sourceModal &&
              <button
                className={styles.sourceButton}
                onClick={handleOpenSourceModal}
                onKeyDown={(e) => e.key == "Enter" && handleOpenSourceModal}
              >
                <div className={styles.sourceButton_icon}>i</div>
                Żródło
              </button>
            }
            {(keepVisible || isActiveSlide) &&
              <Charts
                tickCount={slide?.chart?.tickCount}
                data={slide?.chart?.data}
                bar={slide?.chart?.bar}
                legend={slide?.chart?.legend}
                label={slide?.chart?.label}
                isVertical={slide?.chart?.isVertical}
                isVerticalOnMobile={slide?.chart?.isVerticalOnMobile}
              />}
          </div>
        </div>
        <BottomPanel
          artifacts={slide.artifactsData}
          tasks={slide.tasksData}
          slide={slide.slideSymbol}
        />
        <NextChapterBtn currentSlide={currentSlide} />
      </div>
    </div>
  );
}

export default SlideT2;