import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState
} from "react";
import ReactFullpage from "@fullpage/react-fullpage"
import Layout from './components/Layout';
import { chapterData } from "./data";
import Chapter from "./components/Chapter";
import Modal from "./components/Modal";

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'react-lazy-load-image-component/src/effects/blur.css';

import PersonModal from "./components/PersonModal";
import { motion, AnimatePresence } from "framer-motion";
import SourceModal from "./components/SourceModal";
import ModalWrapper from "./components/Modal/ModalWrapper";
import CookieConsent from "react-cookie-consent";
import Intro from "./components/Intro/Intro";

// declare let fullpage_api: any;

export const ChapterContext = createContext<{
  activeSlideId: number;
  activeChapter: number;
  setActiveChapter: Dispatch<SetStateAction<number>>;
  slidesQuantity: number[];
  fpRef: any;
  modalData: {
    type: "" | "task" | "audio" | "gallery" | "person" | "video" | "source" | "picture",
    data: any
  };
  setModalData: React.Dispatch<any>;
  modalWrapperType: "" | "aboutMuseum" | "authors" | "education",
  setModalWrapperType: React.Dispatch<any>;
  chapterTitles: any[];
}>({
  activeSlideId: 1,
  activeChapter: 1,
  setActiveChapter: (arg) => arg,
  slidesQuantity: [],
  fpRef: null,
  modalData: { type: "", data: null },
  setModalData: (arg) => arg,
  modalWrapperType: "",
  setModalWrapperType: (arg) => arg,
  chapterTitles: [{ chapter: "", name: "" }],
});

function App() {
  const fpRef = useRef(null);

  const [slidesQuantity, setSlidesQuantity] = useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [activeChapter, setActiveChapter] = useState(1);
  const [activeSlideId, setActiveSlideId] = useState(1);
  const [chapterTitles, setChapterTitles] = useState<{ chapter: string, name: string }[]>([]);
  // const [nextChapterId, setNextChapterId] = useState(1);
  const [modalData, setModalData] = useState<{
    type: "" | "task" | "audio" | "gallery" | "person" | "video" | "source" | "picture",
    data: any
  }>({
    type: "",
    data: null
  })
  const [modalWrapperType, setModalWrapperType] = useState<"" | "aboutMuseum" | "authors" | "education">("");
  const [isIntro, setIsIntro] = useState(true);
  // useEffect(() => {
  //   if (nextChapterId > activeChapter) {
  //     fullpage_api.setAllowScrolling(false)
  //   } else {
  //     fullpage_api.setAllowScrolling(true)
  //   }
  // }, [activeSlideId, activeChapter])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const introCookie = localStorage.getItem("wielkie-historie-intro");
  return (
    <ChapterContext.Provider value={{
      activeSlideId,
      activeChapter,
      setActiveChapter,
      slidesQuantity,
      fpRef,
      modalData,
      setModalData,
      modalWrapperType,
      setModalWrapperType,
      chapterTitles
    }}>
      <Layout
        slidesQuantity={slidesQuantity}
        chapterTitles={chapterTitles}
        activeSlideId={activeSlideId}
      >
        <ReactFullpage
          licenseKey={"L4L0J-653Q9-FA5J9-NAMM6-PVVYN"}
          ref={fpRef}
          scrollingSpeed={1000}
          scrollOverflow={windowWidth < 992 ? true : false}
          credits={{ enabled: false, label: "" }}
          lazyLoading
          onLeave={(payload, destination) => {
            setActiveSlideId(destination.index + 1)
            setActiveChapter(parseInt(destination.item.dataset.chapterId as string))
            // setNextChapterId(parseInt(destination.item?.nextSibling.id))
            destination.item.dataset.slideId && setCurrentSlide(parseInt(destination.item.dataset.slideId));
          }}
          render={() => {
            return (
              <ReactFullpage.Wrapper>
                {chapterData.map((x: any, index: number) => {
                  return (
                    <React.Fragment key={index}>
                      <Chapter
                        chapter={x}
                        slidesQuantity={slidesQuantity}
                        setSlidesQuantity={setSlidesQuantity}
                        chapterTitles={chapterTitles}
                        setChapterTitles={setChapterTitles}
                        currentSlide={currentSlide}
                      />
                    </React.Fragment>
                  )
                })}
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </Layout>
      <AnimatePresence>
        {(modalData.type !== "person" && modalData.type !== "source") && modalData.data && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
          >
            <Modal />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modalData.type === "person" && modalData.data && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
          >
            <PersonModal
              name={modalData.data?.name}
              modalImg={modalData.data?.modalImg}
              modalImgDesc={modalData.data?.modalImgDesc}
              yol={modalData.data?.yol}
              info={modalData.data?.info}
              closeModal={() => setModalData({ type: "", data: null })}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modalData.type === "source" && modalData.data && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
          >
            <SourceModal
              data={modalData.data}
              closeModal={() => setModalData({ type: "", data: null })}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {modalWrapperType && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
          >
            <ModalWrapper />
          </motion.div>
        )}
      </AnimatePresence>
      <CookieConsent
        location="bottom"
        buttonText="Akceptuję"
        cookieName="wielkie-historie-ppp"
        style={{
          background: "#191919",
          color: "#FCF3E7",
          fontFamily: `"Rosario", sans-serif`,
          padding: "10px 20px"
        }}
        buttonStyle={{
          backgroundColor: "#FFB661",
          color: "#202227",
        }}
        enableDeclineButton
        declineButtonStyle={{
          backgroundColor: "#ff4337",
          color: "#FCF3E7",
        }}
        declineButtonText="Odrzucam"
        expires={30}
      >
        Na tej stronie używamy plików cookies do przetwarzania informacji o urządzeniach końcowych i danych osobowych. Przetwarzanie jest wykorzystywane do celów takich jak integracja treści, usług zewnętrznych i elementów stron trzecich, analiza/pomiary statystyczne. Zgoda ta jest dobrowolna, nie jest wymagana do korzystania z naszej strony internetowej i można ją w każdej chwili odwołać.
      </CookieConsent>
      {(isIntro && !introCookie) && <Intro setIsIntro={setIsIntro} />}
    </ChapterContext.Provider>
  );
}

export default App;
