import { FC, useContext } from 'react';
import BottomPanel from '../../components/BottomPanel';
import TextBanner from '../../components/common/TextBanner';
import NextChapterBtn from '../../components/common/NextChapterBtn';
import { ChapterContext } from '../../App';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SlideT4.module.scss';
import ArticleBox from '../../components/ArticleBox';

type Props = {
    slide: any;
    currentSlide: number;
}

const SlideT4: FC<Props> = ({ slide, currentSlide }) => {
    const context = useContext(ChapterContext);
    const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
    return (
        <section className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
            <div className={`${styles.slideWrapper} ${slide.reversed ? styles.rowRewerse : ''}`}>
                <div className={`${styles.slideImgBox} ${slide.slideImg ? '' : styles.positionText}`} tabIndex={0}>
                    {slide.slideImgMobile &&
                        <img src={slide.slideImgMobile} alt={slide.slideImgAlt} className={styles.mobileImg}/>
                    }
                    {slide.slideImg &&
                        <LazyLoadImage
                            alt={slide.slideImgAlt}
                            effect="blur"
                            src={slide.slideImg}
                            className={styles.slideImg}
                        />
                    }
                    <div className={styles.slideImgText}>
                        <TextBanner text={slide.bannerText} />
                    </div>
                </div>
                <ArticleBox slide={slide} />
                <BottomPanel
                    artifacts={slide.artifactsData}
                    tasks={slide.tasksData}
                    slide={slide.slideSymbol}
                />
                <NextChapterBtn currentSlide={currentSlide} />
            </div>
        </section>
    );
}

export default SlideT4;