import { FC, useContext } from 'react';
import BottomPanel from '../../components/BottomPanel';
import TextBanner from '../../components/common/TextBanner';
import NextChapterBtn from '../../components/common/NextChapterBtn';
import { ChapterContext } from '../../App';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SlideT9.module.scss';
import ArticleBox from '../../components/ArticleBox';

type Props = {
  slide: any;
  currentSlide: number;
}

const SlideT9: FC<Props> = ({ slide, currentSlide }) => {
  const context = useContext(ChapterContext);
  const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
  return (
    <section className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
      <div className={`${styles.slideWrapper} ${slide.reversed ? styles.rowRewerse : ''}`}>
        <div className={styles.quote}>
          <LazyLoadImage
            alt="cytat"
            effect="blur"
            src="/assets/quote-red.svg"
          />
          <div
            className={styles.quoteText}
            dangerouslySetInnerHTML={{ __html: slide?.quote?.text }}>

          </div>
          <p className={styles.quoteAuthor}>{slide?.quote?.author}</p>
          <p className={styles.quoteDesc}>{slide?.quote?.desc}</p>
        </div>
        <div className={styles.slideImgBox} tabIndex={0}>
          {
            slide.slideImgMobile &&
            <span className={styles.slideImageMobile}>
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImgMobile}
              />
            </span>
          }
          {
            slide.slideImg &&
            <span className={styles.slideImg}>
              <LazyLoadImage
                alt={slide.slideImgAlt}
                effect="blur"
                src={slide.slideImg}
              />
            </span>
          }
          <div className={styles.slideImgText}>
            <TextBanner text={slide.bannerText} />
          </div>
        </div>
        <ArticleBox slide={slide} />
        <BottomPanel
          artifacts={slide.artifactsData}
          tasks={slide.tasksData}
          slide={slide.slideSymbol}
        />
        <NextChapterBtn currentSlide={currentSlide} />
      </div>
    </section>
  );
}

export default SlideT9;