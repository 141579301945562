import React, { useContext } from 'react'
import styles from './AsideMenuPoint.module.scss'
import { ChapterContext } from '../../../App'
import { sumValuesFromArray } from '../../../utils';

interface Props {
  prevSlidesQuantity: number[];
  activeSlideId: number;
  index: number;
}

const AsideMenuPoint: React.FC<Props> = ({
  prevSlidesQuantity,
  activeSlideId,
  index
}) => {
  const context = useContext(ChapterContext);

  const FormatNumber = (num: number) => {
    if (num < 10) { return `0${num}` } else return num;
  }

  const gotoSlide = (index: number): void => {
    if (typeof context.fpRef !== 'undefined') {
      // @ts-ignore
      context.fpRef?.current?.fullpageApi.moveTo(index, 0)
    }
  }
  const isFirst = context.activeChapter === 1 && index === 0;
  let isActive: boolean;
  let sum: number;
  if (isFirst) {
    sum = 0;
    isActive = activeSlideId === index + 1;
    return (
      <li
        key={index}
        id="1"
        onClick={() => gotoSlide(1)}
        onKeyDown={(e) => e.key == "Enter" && gotoSlide(id)}
        className={`${styles.point} ${isActive && styles.pointActive}`}
        role="menuitem"
        tabIndex={0}
      >
        <p className={`${styles.pointNumber} ${isActive && styles.pointNumberActive}`}>
          {/* {`${context.activeChapter}.${index + 1}`} */}
          {FormatNumber(index + 1)}
        </p>
        <div className={`${styles.pointCircle} ${isActive && styles.pointCircleActive}`}></div>
      </li>
    )
  }
  sum = prevSlidesQuantity.length ? sumValuesFromArray(prevSlidesQuantity) : 0;

  const id = sum + index + 1;
  isActive = activeSlideId === id;
  return (
    <li
      key={index}
      id={id.toString()}
      onClick={() => gotoSlide(id)}
      onKeyDown={(e) => e.key == "Enter" && gotoSlide(id)}
      className={`${styles.point} ${isActive && styles.pointActive}`}
      role="menuitem"
      tabIndex={0}
    >
      <p className={`${styles.pointNumber} ${isActive && styles.pointNumberActive}`}>
        {/* {`${context.activeChapter}.${index + 1}`} */}
        {FormatNumber(index + 1)}
      </p>
      <div className={`${styles.pointCircle} ${isActive && styles.pointCircleActive}`}></div>
    </li>
  )
}

export default AsideMenuPoint