import { FC, useContext } from "react";
import PersonCard from "./PersonCard";
import TextBanner from "../../components/common/TextBanner";
import NextChapterBtn from "../../components/common/NextChapterBtn";
import { reichOfficers } from "./data";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './SlideT3.module.scss';
import BottomPanel from "../../components/BottomPanel";
import { ChapterContext } from "../../App";

type Props = {
    slide: any;
    currentSlide: number;
}

const SlideT3: FC<Props> = ({ slide, currentSlide }) => {
    const context = useContext(ChapterContext);
    const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
    
    return (
        <section className={`container ${styles.bgImg}`}>
            <div className={styles.reichWrapper}>
                <div className={styles.hansfrankBox} tabIndex={0}>
                    <LazyLoadImage
                        alt="Hans Frank przemawiający z mównicy"
                        effect="blur"
                        src="/assets/content/hans_frank3.webp"
                        className={styles.slideImg}
                    />
                    <span className={styles.hansfrankBoxInfo}>
                        <TextBanner text="Hans Frank podczas przemówienia, ze zbiorów Narodowego Archiwum Cyfrowego" />
                    </span>
                </div>
                <ul className={styles.officersBox} role="article">
                    {reichOfficers.map(i => {
                        return (
                            <PersonCard
                                key={i.name}
                                img={i.img}
                                modalImg={i.modalImg}
                                quote={i.quote}
                                name={i.name}
                                yol={i.yearsOfLiving}
                                info={i.info}
                            />
                        )
                    })}
                </ul>
                <NextChapterBtn currentSlide={currentSlide} />
            </div>
            <BottomPanel
                artifacts={slide.artifactsData}
                tasks={slide.tasksData}
                slide={slide.slideSymbol}
            />
        </section>
    );
}

export default SlideT3;