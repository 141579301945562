import React from "react";
import styles from "./Authors.module.scss";
import { authorsData } from "./data";

const Authors = () => (
  <section className={styles.container}>
    <h2 className={styles.header}>Autorzy</h2>
    {authorsData.map(x => (
      <React.Fragment key={x.id}>
        <h3 className={styles.role}>{x.role}</h3>
        <p className={styles.author}>{x.author}</p>
      </React.Fragment>
    ))}
  </section>
)

export default Authors