import { useContext } from 'react';
import FocusLock from 'react-focus-lock';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from "./ModalWrapper.module.scss";
import { ChapterContext } from '../../../App';
import AboutMuseum from '../../AboutMuseum';
import Authors from '../../Authors';
import PerfectScrollbar from 'react-perfect-scrollbar';
import EducationMaterials from '../../EducationMaterials';

const ModalWrapper = () => {
  const context = useContext(ChapterContext);
  return (
    <FocusLock returnFocus={{ preventScroll: false }}>
      <div className={styles.overflow}>
        <div className={styles.container}>
          <PerfectScrollbar>
            {context.modalWrapperType === "aboutMuseum" && <AboutMuseum />}
            {context.modalWrapperType === "authors" && <Authors />}
            {context.modalWrapperType === "education" && <EducationMaterials />}
          </PerfectScrollbar>
          <button
            className={styles.closeButton}
            onClick={() => context.setModalWrapperType("")}
            onKeyDown={(e) => e.key == "Enter" && context.setModalWrapperType("")}
            role="button"
            aria-label="Zamknij"
            tabIndex={0}
          >
            <LazyLoadImage
              alt="Zamknij"
              effect="blur"
              src="/assets/close_modal.svg"
            />
          </button>
          <span className={`${styles.dot} ${styles.dot1}`} />
          <span className={`${styles.dot} ${styles.dot2}`} />
          <span className={`${styles.dot} ${styles.dot3}`} />
          <span className={`${styles.dot} ${styles.dot4}`} />
        </div>
      </div>
    </FocusLock >
  );
}

export default ModalWrapper;
