import { useEffect, useState } from 'react';
import { Text } from 'recharts';
import styles from "./Charts.module.scss";

const CustomXAxisTick = (props: any) => {
  const {
    payload,
    x,
    y,
    labelIcons,
    isVertical,
    index
  } = props;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const isMobile = windowWidth < 992;

  if (labelIcons?.length) {
    return (
      <image
        x={isMobile ? 10 : -25}
        y={isMobile ? y - 40 : y - 50}
        width={isMobile ? labelIcons[index]?.width - 30 : labelIcons[index]?.width}
        height={labelIcons[index]?.height}
        href={labelIcons[index]?.src}
        className={styles.labelIcon}
      />
    )
  }

  return (
    <Text
      x={isVertical ? x - 10 : x}
      y={y + 10}
      width={92}
      angle={isVertical ? 90 : 0}
      textAnchor="middle"
      verticalAnchor="start"
      lineHeight={isMobile ? 15 : 22}
    >
      {payload.value}
    </Text>
  )
}

export default CustomXAxisTick