import { FC, useContext } from "react";
import BottomPanel from "../../components/BottomPanel";
import NextChapterBtn from "../../components/common/NextChapterBtn";
import { ChapterContext } from "../../App";

import styles from "./SlideT6.module.scss";
import ArticleBox from "../../components/ArticleBox";

interface Props {
    slide: any;
    currentSlide: number;
}

const SlideT6: FC<Props> = ({ slide, currentSlide }) => {
    const context = useContext(ChapterContext);
    const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
    return (
        <section className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
            <div className={`${styles.slideWrapper} ${slide.reversed ? styles.rowRewerse : ''}`}>
                <div className={styles.slideFirstColumn} tabIndex={0}>
                    <p>
                        <span className={styles.whiteText}>{slide.paragraph2}</span>
                        <span className={styles.redText}>{slide.paragraph3}</span>
                    </p>
                </div>
                <ArticleBox slide={slide}/>
                <BottomPanel
                    artifacts={slide.artifactsData}
                    tasks={slide.tasksData}
                    slide={slide.slideSymbol}
                />
                <NextChapterBtn currentSlide={currentSlide} />
            </div>
        </section>
    );
}

export default SlideT6;