export const authorsData = [
  {
    id: 1,
    role: "KONCEPCJA, TREŚCI, POZYSKIWANIE MATERIAŁÓW AUDIOWIZUALNYCH",
    author: "Maciej Piwowarczuk, Zuzanna Liepelt, Pola Gradowska, Wojciech Surała, Grzegorz Miśta, Bartłomiej Kozieł, zespół Fundacji na rzecz wielkich Historii"
  },
  {
    id: 2,
    role: "KOORDYNACJA PROJEKTU",
    author: "Maciej Piwowarczuk, Zuzanna Liepelt"
  },
  {
    id: 3,
    role: "INTRO",
    author: "Szymon Felkel (animacja), Maciej Piwowarczuk (scenariusz)"
  },
  {
    id: 4,
    role: "Koordynacja techniczna",
    author: "Grzegorz Kowalski"
  },
  {
    id: 5,
    role: "Projekt graficzny",
    author: "Paweł Woźniak"
  },
  {
    id: 6,
    role: "Programowanie",
    author: "Paweł Pitura, Marcin Żabicki"
  },
  {
    id: 7,
    role: "PROJEKT I WYKONANIE",
    author: "CodeKingdom.IT"
  },
  {
    id: 8,
    role: "FOTOGRAFIE POCHODZĄ ZE ZBIORÓW",
    author: "DigiPunkt (Fundacja na rzecz Wielkich Historii), Narodowe Archiwum Cyfrowe, Wikimedia Commons (domena publiczna), Biblioteka Narodowa (Polona.pl), Centralne Archiwum Wojskowe, East News – agencja fotograficzna, Archiwum Państwowe Muzeum Auschwitz-Birkenau w Oświęcimiu, Fundacja Generał Elżbiety Zawackiej, dwutygodnik „Stolica”, Muzeum Regionalne w Siedlcach, Muzeum Powstania Warszawskiego, Archiwum Państwowe w Siedlcach, FoKa / Forum, Alamy / Bew"
  },
  {
    id: 9,
    role: "PARTNERZY",
    author: "Biblioteka Uniwersytecka w Warszawie, Radio Wolna Europa, Muzeum Historii Polski, Muzeum Pamięci Powstania Warszawskiego, Muzeum Regionalne w Stalowej Woli, Videre sp. z o. o., Muzeum Niepodległości, Muzeum Regionalne w Siedlcach, Fundacja Generał Elżbiety Zawackiej. Archiwum i Muzeum Pomorskie Armii Krajowej oraz Wojskowej Służby Kobiet"
  },
]