import { FC, useContext } from 'react';
import BottomPanel from '../../components/BottomPanel';
import TextBanner from '../../components/common/TextBanner';
import NextChapterBtn from '../../components/common/NextChapterBtn';
import { ChapterContext } from '../../App';

import styles from './SlideT7.module.scss';
import ReactPlayer from 'react-player/lazy'
import ArticleBox from '../../components/ArticleBox';

type Props = {
  slide: any;
  currentSlide: number;
}

const SlideT7: FC<Props> = ({ slide, currentSlide }) => {
  const context = useContext(ChapterContext);
  const isNextSlide = parseInt(slide.slideSymbol.split(".")[1]) < context.slidesQuantity[context.activeChapter - 1];
  return (
    <section className={`container ${styles.bgImg}`} style={{ backgroundImage: `url(${slide.backgroundImage})` }}>
      <div className={`${styles.slideWrapper} ${slide.reversed ? styles.rowRewerse : ''}`}>
        <div className={styles.video} tabIndex={0}>
          <div className={styles.videoWrapper}>
            <div className={styles.videoPlayer}>
              <ReactPlayer
                url={slide?.video}
                width="100%"
                height="100%"
                controls
              />
            </div>
          </div>
          <div className={styles.slideVideoText}>
            <TextBanner text={slide.bannerText} />
          </div>
        </div>
        <ArticleBox slide={slide} />
        <BottomPanel
          artifacts={slide.artifactsData}
          tasks={slide.tasksData}
          slide={slide.slideSymbol}
        />
        <NextChapterBtn currentSlide={currentSlide} />
      </div>
    </section >
  );
}

export default SlideT7;