import { FC, useContext, useEffect } from "react";

import styles from "./NextChapterBtn.module.scss";
import { ChapterContext } from "../../../App";
import { isLastSlide } from "../../../utils";

interface Props {
  currentSlide: number;
}

const NextChapterBtn: FC<Props> = ({ currentSlide }) => {
  const context = useContext(ChapterContext);

  const gotoNextSlide = () => {
    if (typeof context.fpRef !== 'undefined') {
      context.fpRef?.current?.fullpageApi.moveTo(context.activeSlideId + 1, 0);
    }
  }

  const lastSlideInChapter = context.slidesQuantity[context.activeChapter - 1] === currentSlide;

  return (
    <>
      {
        !isLastSlide(context.slidesQuantity, context.activeSlideId) &&
        <div className={styles.mBtnBox} datatype={"chapter" + (lastSlideInChapter ? context.activeChapter + 1 : context.activeChapter)} >
          <div className={styles.mBtnPin}>
            {lastSlideInChapter 
              ? <img src='/assets/arrow-bottom.svg' alt=''/>
              : currentSlide + 1
            }
          </div>
          <button
            className={styles.mBtn}
            onClick={gotoNextSlide}
            onKeyDown={(e) => e.key == "Enter" && gotoNextSlide}
            aria-label="Następna strona"
            tabIndex={0}
            role="button"
          >
            {lastSlideInChapter 
              ? <>
                <p>{context.chapterTitles[context.activeChapter].chapter}</p>
                <p>{context.chapterTitles[context.activeChapter].name}</p>
              </>
              : 'Następna strona'
            }
          </button>
        </div>
      }
    </>
  );
}

export default NextChapterBtn;