import { useEffect, useRef, useState } from "react";
import FocusLock from 'react-focus-lock';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from "./ZoomModal.module.scss";

type Props = {
  activeImage: string;
  setActiveImage: (x: string) => void;
}

const ZoomModal: React.FC<Props> = ({ activeImage, setActiveImage }) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const imageRef = useRef<HTMLDivElement>(null)

  let imagePosition = { x: 50, y: 50 };
  let cursorPosBefore = { x: 0, y: 0 };
  let imagePosBefore = { x: -1, y: -1 };
  let imagePosAfter = imagePosition;

  const minMax = (pos: number) => (pos < 0) ? 0 : (pos > 100) ? 100 : pos;

  const setNewCenter = (x: number, y: number) => {
    imagePosAfter = { x: x, y: y };
    if (imageRef && imageRef?.current) {
      imageRef.current.style.backgroundPosition = `${x}% ${y}%`;
    }
  };

  function onMouseDown(e: any) {
    cursorPosBefore = { x: e.clientX, y: e.clientY };
    imagePosBefore = imagePosAfter; // Get current image position
  };

  function onMouseMove(e: any) {
    e.preventDefault();

    if (e.buttons === 0) return;

    let newXPos = -1;
    let newYPos = -1;

    if (imageRef && imageRef?.current) {
      newXPos = imagePosBefore.x + ((cursorPosBefore.x - e.clientX) / imageRef.current.clientWidth * 100 + zoomLevel);
      newYPos = imagePosBefore.y + ((cursorPosBefore.y - e.clientY) / imageRef.current.clientHeight * 100 + zoomLevel);
    }
    setNewCenter(minMax(newXPos), minMax(newYPos));
  }

  useEffect(() => {
    if (imageRef && imageRef?.current) {
      imageRef.current.addEventListener("mousedown", (onMouseDown));
      imageRef.current.addEventListener("mousemove", (onMouseMove));
      imageRef.current.addEventListener("touchstart", (onTouch));
      imageRef.current.addEventListener("touchmove", (onTouchMove));
    }
  }, []);

  function onTouch(e: TouchEventInit) {
    cursorPosBefore = { x: e.touches && e.touches[0].clientX || cursorPosBefore.x, y: e.touches && e.touches[0].clientY || cursorPosBefore.y };
    imagePosBefore = imagePosAfter;
  }

  function onTouchMove(e: TouchEvent) {

    let newXPos = -1;
    let newYPos = -1;

    if (imageRef && imageRef?.current) {
      newXPos = imagePosBefore.x + ((cursorPosBefore.x - e.touches[0].clientX) / imageRef.current.clientWidth * 100 + zoomLevel);
      newYPos = imagePosBefore.y + ((cursorPosBefore.y - e.touches[0].clientY) / imageRef.current.clientHeight * 100 + zoomLevel);
    }

    setNewCenter(minMax(newXPos), minMax(newYPos));
  }

  return (
    <FocusLock returnFocus={{ preventScroll: false }}>
      <div className={styles.container}>
        <button
          className={`${styles.button} ${styles.backButton}`}
          onClick={() => setActiveImage("")}
          onKeyDown={(e) => e.key == "Enter" && setActiveImage("")}
          role="button"
          aria-label="Powrót"
          tabIndex={0}
        >
          <LazyLoadImage
            alt="Powrót"
            effect="blur"
            src="/assets/back_icon.svg"
          />
          <p>Powrót</p>
        </button>
        <div className={styles.wrapper}>
          <div className={styles.imageWrapper}>
            <div
              ref={imageRef}
              className={styles.image}
              style={{
                backgroundImage: `url(${activeImage})`,
                backgroundSize: `${100 + zoomLevel}%`,
              }}
            >
            </div>
          </div>
          <div className={styles.zoomTool}>
            <button
              className={`${styles.button} ${styles.zoom}`}
              onClick={() => zoomLevel >= 10 && setZoomLevel(zoomLevel - 10)}
              onKeyDown={(e) => e.key == "Enter" && setZoomLevel(zoomLevel - 10)}
              role="button"
              aria-label="Pomniejsz"
              tabIndex={0}
            >
              <LazyLoadImage
                alt="Pomniejsz"
                effect="blur"
                src="/assets/zoom_out.svg"
              />
            </button>
            <input
              type="range"
              id="points"
              name="points"
              min="1"
              max="100"
              value={zoomLevel}
              onInput={(e: any) => setZoomLevel(parseInt(e.currentTarget.value))}
              style={{
                background: `linear-gradient(to right, #ffdeb1 ${zoomLevel}%, #161719 ${zoomLevel}%)`,
              }}
            />
            <button
              className={`${styles.button} ${styles.zoom}`}
              onClick={() => zoomLevel <= 90 && setZoomLevel(zoomLevel + 10)}
              onKeyDown={(e) => e.key == "Enter" && setZoomLevel(zoomLevel - 10)}
              role="button"
              aria-label="Powiększ"
              tabIndex={0}
            >
              <LazyLoadImage
                alt="Powiększ"
                effect="blur"
                src="/assets/zoom_in.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </FocusLock>
  );
}

export default ZoomModal;
