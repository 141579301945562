import { useContext, useState } from "react";
import { ChapterContext } from "../../App";
import ZoomModal from "./ZoomModal";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import TextBanner from "../common/TextBanner";
import Player from "./Player";
import { motion, AnimatePresence } from "framer-motion";
import PerfectScrollbar from 'react-perfect-scrollbar';
import FocusLock from 'react-focus-lock';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player/lazy'
import styles from "./Modal.module.scss";

const Modal = () => {
  const [zoomedImage, setZoomedImage] = useState("");
  const [activeTask, setActiveTask] = useState(0);
  const context = useContext(ChapterContext);

  const isGalleryType = context.modalData.type === "gallery";
  const isTaskType = context.modalData.type === "task";
  const isAudioType = context.modalData.type === "audio";
  const isVideoType = context.modalData.type === "video";
  const isPictureType = context.modalData.type === "picture";

  const handleCloseModal = () => {
    context.setModalData({ type: "", data: null })
  }

  return (
    <FocusLock returnFocus={{ preventScroll: false }}>
      <div className={styles.container}
        onClick={() => {
          handleCloseModal();
        }}
      >
        <div className={styles.wrapper}
          onClick={(event) => {
            event?.stopPropagation();
          }}
        >
          <div className={`${styles.content} ${!isTaskType && styles.contentFullWidth}`}>
            {(isGalleryType || isTaskType) &&
              <div className={styles.carousel}>
                <Swiper
                  modules={[Navigation]}
                  spaceBetween={100}
                  slidesPerView={1}
                  centeredSlides
                  breakpoints={{
                    768: {
                      slidesPerView: 3
                    }
                  }}
                  navigation={{
                    nextEl: ".next-arrow",
                    prevEl: ".prev-arrow"
                  }}
                >
                  {isGalleryType && context.modalData?.data?.map((x: any, i: number) => (
                    <SwiperSlide key={x.id} className={styles.activeSlide}>
                      <div className={styles.carouselSlide}>
                        <LazyLoadImage
                          alt={x.title}
                          effect="blur"
                          src={x.img}
                        />
                        <div className={`slideTitle ${styles.titleWrapper}`}>
                          <p className={styles.number}>{`${i + 1} / ${context.modalData?.data?.length}`}</p>
                          <TextBanner text={x.title} bgColor="#212228" />
                        </div>
                        <button
                          className={`zoom ${styles.zoom}`}
                          onClick={() => setZoomedImage(x.img)}
                          onKeyDown={(e) => e.key == "Enter" && setZoomedImage(x.img)}
                          role="button"
                          aria-label="Zoom"
                          tabIndex={0}
                        >
                          <LazyLoadImage
                            alt="Zoom"
                            effect="blur"
                            src="/assets/zoom_icon.svg"
                          />
                        </button>
                      </div>
                    </SwiperSlide>
                  ))}
                  {/* {isTaskType && context.modalData?.data?.gallery?.map((x: any, i: number) => (
                  <SwiperSlide key={x.id} className={styles.activeSlide}>
                    <div className={styles.carouselSlide}>
                      <img src={x.img} alt={x.title} />
                      <div className={`slideTitle ${styles.titleWrapper}`}>
                        <TextBanner text={x.title} bgColor="#212228" />
                      </div>
                      <button
                        className={`zoom ${styles.zoom}`}
                        onClick={() => setZoomedImage(x.img)}
                        onKeyDown={(e) => e.key == "Enter" && setZoomedImage(x.img)}
                        role="button"
                        aria-label="Zoom"
                        tabIndex={0}
                      >
                        <LazyLoadImage
                          alt="Zoom"
                          effect="blur"
                          src="/assets/zoom_icon.svg"
                        />
                      </button>
                    </div>
                  </SwiperSlide>
                ))} */}
                  {isTaskType && context.modalData.data.tasks[activeTask].files && context.modalData.data.tasks[activeTask].files.map((x: any, i: number) => (
                    <SwiperSlide key={x.id} className={styles.activeSlide}>
                      <div className={styles.carouselSlide}>
                        <LazyLoadImage
                          alt={x.title}
                          effect="blur"
                          src={x.img}
                        />
                        <div className={`slideTitle ${styles.titleWrapper}`}>
                          <TextBanner text={x.title} bgColor="#212228" />
                        </div>
                        <button
                          className={`zoom ${styles.zoom}`}
                          onClick={() => setZoomedImage(x.img)}
                          onKeyDown={(e) => e.key == "Enter" && setZoomedImage(x.img)}
                          role="button"
                          aria-label="Zoom"
                          tabIndex={0}
                        >
                          <LazyLoadImage
                            alt="Zoom"
                            effect="blur"
                            src="/assets/zoom_icon.svg"
                          />
                        </button>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <button
                  className={`prev-arrow ${styles.arrow} ${styles.prevArrow}`}
                  role="button"
                  aria-label="Poprzedni slajd"
                  tabIndex={0}
                >
                  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.87959e-06 21.5C2.91766e-06 9.62587 9.62588 -2.91766e-06 21.5 -1.87959e-06C33.3741 -8.41521e-07 43 9.62588 43 21.5C43 33.3741 33.3741 43 21.5 43C9.62588 43 8.41521e-07 33.3741 1.87959e-06 21.5Z" fill="#26282F" fillOpacity="0.2" /><path d="M0.500002 21.5C0.500003 9.90202 9.90202 0.499997 21.5 0.499998C33.098 0.499999 42.5 9.90202 42.5 21.5C42.5 33.098 33.098 42.5 21.5 42.5C9.90202 42.5 0.500001 33.098 0.500002 21.5Z" stroke="#CFCFCF" strokeOpacity="0.1" /><path d="M24 27L18 21.5L24 16" stroke="#FCF3E7" strokeWidth="2" /></svg>
                </button>
                <button
                  role="button"
                  aria-label="Kolejny slajd"
                  className={`next-arrow ${styles.arrow} ${styles.nextArrow}`}
                  tabIndex={0}
                >
                  <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="21.4995" cy="21.5" r="21.5" fill="#26282F" fillOpacity="0.2" /><circle cx="21.4995" cy="21.5" r="21" stroke="#CFCFCF" strokeOpacity="0.1" /><path d="M18.9995 16L24.9995 21.5L18.9995 27" stroke="#FCF3E7" strokeWidth="2" /></svg>
                </button>

              </div>
            }
            <AnimatePresence>
              {zoomedImage && (
                <motion.div
                  initial={{ opacity: 0, zIndex: 100 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <ZoomModal
                    activeImage={zoomedImage}
                    setActiveImage={setZoomedImage}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            {isAudioType && (
              <div className={styles.audioContainer}>
                <div className={styles.audioLeftColumn}>
                  <Player />
                </div>
                <div className={styles.audioRightColumn}>
                  <h3 className={styles.audioHeader}>{context.modalData?.data?.header}</h3>
                  <p className={styles.audioSubheader}>{context.modalData?.data?.subheader}</p>
                  <PerfectScrollbar style={{ maxHeight: "450px" }}>
                    <div className={styles.audioDesc} dangerouslySetInnerHTML={{ __html: context.modalData?.data?.desc }}>

                    </div>
                  </PerfectScrollbar>
                </div>
              </div>
            )}
            {isVideoType && (
              <div className={styles.videoContainer} tabIndex={0}>
                <div className={styles.videoWrapper}>
                  <div className={styles.videoPlayer}>
                    <ReactPlayer
                      url={context.modalData.data.video}
                      width="100%"
                      height="100%"
                      controls
                    />
                  </div>
                </div>
                <div className={styles.slideVideoText}>
                  <TextBanner text={context.modalData.data.title} />
                </div>
              </div>
            )}
            {isPictureType && (
              <div className={styles.pictureContainer}>
                <img src={context.modalData.data.modalImg} alt={context.modalData.data.modalImgAlt} />
                <TextBanner text={context.modalData.data.modalTxt}/>
              </div>
            )}
          </div>
          {isTaskType &&
            <div className={styles.taskContainer}>
              <div className={styles.task}>
                <div className={styles.taskContent}>
                  <h2 className={styles.taskHeader}>{context.modalData?.data?.tasks[activeTask]?.header}</h2>
                  <p className={styles.taskLevel}>{context.modalData?.data?.tasks[activeTask]?.level}</p>
                  <p className={styles.taskCommand}>{context.modalData?.data?.tasks[activeTask]?.title}</p>
                  <ul role="list">
                    {context.modalData?.data?.tasks[activeTask].content?.map((x: any) => (
                      <li key={x.id} className={styles.taskItem} role="listitem">
                        {x.title}
                        {x.addQuestions && <ol className={styles.minorList}>
                          {
                            x.addQuestions.map((y:string, id:number) => {
                              return(
                                <li key={id}>{y}</li>
                              )
                            })
                          }  
                        </ol>}
                      </li>
                    ))}
                  </ul>
                </div>
                <ul className={styles.taskRules} role="list">
                  {context.modalData?.data?.tasks[activeTask].rules?.map((x: any) => (
                    <li key={x.id} className={styles.taskRule} role="listitem">
                      <LazyLoadImage
                        alt={x.title}
                        effect="blur"
                        src={x.icon}
                      />
                      {x.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.taskTabs}>
                <div className={styles.taskTabsMobile}>
                  <LazyLoadImage
                    alt="Ikonka"
                    effect="blur"
                    src="/assets/pencil.svg"
                  />
                  <p>Zadania:</p>
                </div>
                <ul className={styles.taskTabsList} role="tablist">
                  {context.modalData?.data?.tasks.map((x: any, i: number) => {
                    const isActive = i === activeTask;
                    return (
                      <li
                        key={i}
                        className={`${styles.taskTab} ${isActive && styles.taskTabActive}`}
                        onClick={() => setActiveTask(i)}
                        onKeyDown={(e) => e.key == "Enter" && setActiveTask(i)}
                        role="button"
                        tabIndex={0}
                      >
                        {i + 1}
                      </li>
                    )
                  })
                  }
                </ul>
              </div>
            </div>}
          <button
            className={styles.closeButton}
            onClick={handleCloseModal}
            onKeyDown={(e) => e.key == "Enter" && handleCloseModal}
            role="button"
            aria-label="Zamknij"
            tabIndex={0}
          >
            <LazyLoadImage
              alt="Zamknij"
              effect="blur"
              src="/assets/close_modal.svg"
            />
          </button>
          <span className={`${styles.dot} ${styles.dot1}`} />
          <span className={`${styles.dot} ${styles.dot2}`} />
          <span className={`${styles.dot} ${styles.dot3}`} />
          <span className={`${styles.dot} ${styles.dot4}`} />
        </div>
      </div>
    </FocusLock>
  );
}

export default Modal;
