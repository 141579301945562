export const reichOfficers = [
    {
        img: '/assets/content/hans_frank2.webp',
        modalImg: '/assets/content/hans_frank2.webp',
        quote: 'Polakom należy umożliwić kształcenie się jedynie w takim zakresie, aby uświadomili sobie, że jako naród nie mają żadnych perspektyw',
        name: 'Hans Frank',
        yearsOfLiving: '1900-1946',
        info: 'prawnik, przed dojściem Hitlera do władzy obrońca członków partii NSDAP. Od 1927 w partii, od 1930 poseł Reichstagu. W latach 1939-1945 generalny gubernator okupowanych ziem polskich. Podczas trybunału w Norymberdze skazany na karę śmierci.'
    },
    {
        img: '/assets/content/heinrich_himler.webp',
        modalImg: '/assets/content/heinrich_himler.webp',
        quote: 'Celem takiej szkoły ma być wyłącznie proste liczenie najwyżej do 500, napisanie nazwiska, nauka, że nakazem bożym jest posłuszeństwo wobec Niemców, uczciwość, pilność i grzeczność. Czytania nie uważam za koniecznie',
        name: 'Heinrich Himler',
        yearsOfLiving: '1900-1945',
        info: 'szef SS i Gestapo, pod sobą miał cały aparat totalitarnego terroru, odpowiedzialny za śmierć milionów osób, najbardziej zaufany współpracownik Hitlera. Po kapitulacji III Rzeszy zostaje schwytany  przez Brytyjczyków. Popełnia samobójstwo podczas przesłuchania.'
    }
]