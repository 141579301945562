import Artifacts from "./Artifacts"
import TasksButton from "./TasksButton"

type Props = {
  artifacts: any;
  tasks: any;
  slide: string;
}

const BottomPanel: React.FC<Props> = ({ artifacts, tasks, slide }) => (
  <>
    <Artifacts data={artifacts} />
    <TasksButton data={tasks} slide={slide} />
  </>
)

export default BottomPanel