import { FC } from "react";

import styles from "./ArticleBox.module.scss";

interface Props {
    slide: any;
}

const ArticleBox:FC<Props> = ({slide}) => {
    return (
        <article className={styles.slideArticle} tabIndex={0}>
            {slide.title && <h2>{slide.title}</h2>}
            {slide.paragraph1 && <p>{slide.paragraph1}</p>}
            {slide.paragraph2 && <p>{slide.paragraph2}</p>}
            {slide.description && 
                <div className={styles.slideDesc}>
                    <p className={styles.slideDescContent}>{slide.description}</p>
                </div>
            }
            {slide.paragraph3 && <p>{slide.paragraph3}</p>}
        </article>
    );
}
 
export default ArticleBox;