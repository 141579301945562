import { FC } from 'react';
import FocusLock from 'react-focus-lock';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './PersonModal.module.scss';

interface Props {
    modalImg: string,
    modalImgDesc?: string,
    name: string,
    yol: string,
    info: string,
    closeModal: () => void
}

const PersonModal: FC<Props> = ({ modalImg, modalImgDesc, name, yol, info, closeModal }) => {

    return (
        <FocusLock returnFocus={{ preventScroll: false }} >
            <div
                className={styles.modalBg}
                onClick={() => {
                    closeModal();
                }}
            >
                <article className={styles.modalBorder}
                    onClick={(event) => {
                        event?.stopPropagation();
                    }}
                >
                    <div className={styles.modalWindow}>
                        <div className={styles.modalWindow_imgBox}>
                            {modalImg &&
                                <LazyLoadImage
                                    alt={name}
                                    effect="blur"
                                    src={modalImg}
                                    className={styles.modalImg}
                                />
                            }
                            {modalImgDesc && <p>{modalImgDesc}</p>}
                        </div>
                        <div className={styles.modalTextBox}>
                            <h2>{name}</h2>
                            <h3>{yol}</h3>
                            <p>{info}</p>
                        </div>
                        <button
                            onClick={() => closeModal()}
                            className={styles.closeBtn}
                            onKeyDown={(e) => e.key == "Enter" && closeModal()}
                            role="button"
                            aria-label="Zamknij"
                            tabIndex={0}
                        />
                    </div>
                    <span className={`${styles.dot} ${styles.dot1}`} />
                    <span className={`${styles.dot} ${styles.dot2}`} />
                    <span className={`${styles.dot} ${styles.dot3}`} />
                    <span className={`${styles.dot} ${styles.dot4}`} />
                </article>
            </div>
        </FocusLock>
    );
}

export default PersonModal;