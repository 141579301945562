import React, { useContext } from 'react'
import styles from './AsideMenu.module.scss'
import { ChapterContext } from '../../../App'
import AsideMenuPoint from './AsideMenuPoint';

interface Props {
  activeSlideId: number;
  slidesQuantity: number[];
}

const AsideMenu: React.FC<Props> = ({ activeSlideId, slidesQuantity }) => {
  const context = useContext(ChapterContext);

  const slidesLength = slidesQuantity.length && slidesQuantity[context.activeChapter - 1];
  const arr = Array.from(Array(slidesLength).keys());
  const prevSlidesQuantity = context.activeChapter >= 1 ?
    slidesQuantity.slice(0, context.activeChapter - 1) : [];
  return (
    <aside className={styles.container}>
      <ul className={styles.pointList} role="menu">
        {arr.map((x, index) => (
          <React.Fragment key={index}>
            <AsideMenuPoint
              prevSlidesQuantity={prevSlidesQuantity}
              activeSlideId={activeSlideId}
              index={index}
            />
          </React.Fragment>
        ))}
      </ul>
    </aside>
  )
}

export default AsideMenu