import { FC } from "react";
import styles from "./TextBanner.module.scss";

interface Props {
    text: string;
    bgColor?: string;
}

const TextBanner: FC<Props> = ({ text, bgColor }) => {
    return (
        <div className={`bannerBox ${styles.bannerBox}`} style={{ background: bgColor }}>
            <p className={styles.bannerText}>
                {text}
            </p>
            <span className={`${styles.dot} ${styles.dot1}`} />
            <span className={`${styles.dot} ${styles.dot2}`} />
            <span className={`${styles.dot} ${styles.dot3}`} />
            <span className={`${styles.dot} ${styles.dot4}`} />
        </div>
    );
}

export default TextBanner;