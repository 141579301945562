import { Text } from 'recharts';
import styles from "./Charts.module.scss";

const CustomLabelList = (props: any) => {
  const { x, y, name } = props;
  return (
    <Text
      x={x}
      y={y + 50}
      width={300}
      className={styles.barCustomLabel}
    >
      {name}
    </Text>
  )
}

export default CustomLabelList